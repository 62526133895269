/* @ts-ignore */

import { AlternativeToRoadblock } from './AlternatievEmailSubscribe';
import { BlueEmailSubscribe } from './BlueEmailSubscribe';
import { RoadblockProps } from './types';

export const Roadblock = function ({ type, componentName }: RoadblockProps) {
  if (type === 'blue-email-subscribe') {
    return <BlueEmailSubscribe componentName={componentName} />;
  }

  if (type === 'alternative-to-roadblock') {
    return <AlternativeToRoadblock componentName={componentName} />;
  }

  return <></>;
};

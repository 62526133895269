import { Heading } from '@appsumo/dorado-react';
import {
  faCircleCheck,
  faCircleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { memo, useMemo } from 'react';

import { Button } from '~/components/ui';
import useUser from '~/lib/user';
import { GA4FormContentValue } from '~/lib/util/constants';
import { useEmailSubscribe } from '~/lib/util/hooks';
import { UseEmailSubscribeProps } from '~/lib/util/hooks/emailSubscribe';

interface ComponentNameProps {
  componentName?: string;
  content?: string;
}

const EmailErrorMessage = memo(
  ({ errorMessage }: { errorMessage: string }): React.ReactElement => {
    if (!errorMessage) return <></>;

    return (
      <div className="mt-2">
        <FontAwesomeIcon
          icon={faCircleExclamation}
          height="16"
          className="mr-2"
        />
        <span className="text-sm">{errorMessage}</span>
      </div>
    );
  },
);

EmailErrorMessage.displayName = 'EmailErrorMessage';

export const EmailCapture = ({
  componentName,
  content,
}: ComponentNameProps): React.ReactElement => {
  const emailSubscribeProps: UseEmailSubscribeProps = {
    customFormData: { component: componentName, content },
  };

  const {
    hasEmailError,
    isEmailSubmitted,
    isEmailValid,
    onChangeEmail,
    submitEmail,
  } = useEmailSubscribe(emailSubscribeProps);

  function onSubmit(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    submitEmail();
  }

  const errorMessage = useMemo(() => {
    if (!isEmailValid) {
      return 'A valid email address is required to get 10% off.';
    }

    if (hasEmailError) {
      return hasEmailError;
    }

    return '';
  }, [hasEmailError, isEmailValid]);

  if (isEmailSubmitted) {
    return (
      <div className="mt-4 flex items-center rounded bg-dollar-light-40 px-4 py-3 text-midnight">
        <FontAwesomeIcon
          icon={faCircleCheck}
          height="18"
          className="mr-2 text-lg"
        />
        <span className="text-sm">You&apos;re in! Thanks for signing up.</span>
      </div>
    );
  }

  return (
    <div className="w-full">
      <label htmlFor="email" className="mb-2 block font-header font-semibold">
        Email
      </label>
      <div className="flex w-full flex-col gap-2">
        <div
          className={clsx(
            'grow rounded border-2',
            errorMessage ? 'border-brick' : ' border-transparent',
          )}
        >
          <input
            className={clsx(
              'w-full rounded border px-4 py-2 text-midnight',
              errorMessage
                ? 'rounded-none border-brick focus:border-brick focus:outline-none'
                : ' border-grace',
            )}
            onChange={onChangeEmail}
            id="email"
          />
        </div>

        <Button secondary onClick={onSubmit} className="shrink-0 !px-7 !py-2">
          Get 10% off
        </Button>
      </div>
      <EmailErrorMessage errorMessage={errorMessage} />
    </div>
  );
};

export const AlternativeToRoadblock = ({
  componentName,
}: ComponentNameProps) => {
  const { user } = useUser();

  // Hide for logged in users
  if (user?.email) return <></>;

  return (
    <div className="flex flex-col gap-4 rounded bg-blue-200 p-6">
      <div>
        <Heading.H4 className="text-xl">
          Get 10% off your first purchase
        </Heading.H4>
        <p className="mt-2">
          Plus the hottest deals on software sent straight to your inbox
        </p>
      </div>

      <EmailCapture
        componentName={componentName}
        content={GA4FormContentValue.alternativeTo10Off}
      />
    </div>
  );
};

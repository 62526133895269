import { Button, TacoRating, ThumbnailGallery } from '@appsumo/dorado-react';
import { useMemo } from 'react';
import { ShareType } from '~/constants/global';
import { DEAL_COMMENT_TYPE } from '~/lib/discussions/constants';
import { QuestionV2 } from '~/lib/discussions/types';
import LikeButton from '../../app/components/LikeButton';
import AskSumoBox from '../discussions/AskSumoBox';
import QuestionCard from '../discussions/card/QuestionCard';
import ShareModal from '../global/ShareModal';
import CommentUserInformation from '../product/Comments/CommentUserInformation';
import { DealSkuCardType } from '../sku';
import { parseImages, TopFeatures } from '../sku/AlternativeTo';
import Accordion from './Accordion';

interface ProductSummaryCardProps {
  question?: QuestionV2;
  deal: DealSkuCardType;
  initialLikes?: number;
}

const ProductSummaryCard: React.FC<ProductSummaryCardProps> = ({
  deal,
  question,
  initialLikes,
}) => {
  const imageList = useMemo(() => parseImages(deal as any), [deal]);

  const accordionItems = [
    {
      title: 'Questions',
      content: (
        <div>
          {question ? (
            <>
              <QuestionCard question={question} />
              <div>
                <Button
                  href={`/products/${deal.slug}/questions`}
                  tertiary
                  className="mt-4 inline-block"
                >
                  See all questions
                </Button>
              </div>
            </>
          ) : (
            <>
              <p className="mb-4 font-header text-xl font-bold">
                No one has asked a question... yet
              </p>
              <CommentUserInformation
                type={DEAL_COMMENT_TYPE.QUESTION}
                isPDPPage={true}
              />
            </>
          )}
        </div>
      ),
    },
    {
      title: 'Review',
      content: (
        <div className="space-y-6">
          {deal.reviews_summary ? (
            <>
              <AskSumoBox summary={deal.reviews_summary || ''} />

              <div>
                <Button
                  href={`/products/${deal.slug}/reviews`}
                  tertiary
                  className="mt-4 inline-block"
                >
                  See all reviews
                </Button>
              </div>
            </>
          ) : (
            <>
              <p className="mb-4 font-header text-xl font-bold">
                No one has reviewed this product... yet
              </p>
              <CommentUserInformation
                type={DEAL_COMMENT_TYPE.REVIEW}
                isPDPPage={true}
              />
            </>
          )}
        </div>
      ),
    },
  ];

  if (deal?.common_features?.length) {
    accordionItems.unshift({
      title: 'Features',
      content: (
        <div>
          <TopFeatures features={deal.common_features} />
        </div>
      ),
    });
  }

  return (
    <article
      id={`summary-${deal.slug}`}
      className="scroll-mt-[165px] rounded-lg border bg-white p-6 shadow-md lg:scroll-mt-[145px]"
    >
      <header>
        {!!deal?.attributes?.best_for?.length && (
          <div className="mb-4">
            <span className="inline-block rounded-full bg-blue-100 px-3 py-1 text-xs font-semibold text-blue-800">
              {deal.attributes.best_for[0]}
            </span>
          </div>
        )}
        <div className="mb-6 flex flex-col sm:flex-row sm:items-start sm:justify-between">
          <div className="mb-4 flex items-start space-x-4 sm:mb-0">
            <div className="relative w-20 shrink-0">
              <ThumbnailGallery
                galleryImages={imageList}
                className={`aspect-sku-card overflow-hidden rounded`}
                image={{
                  src: deal.media_url,
                  alt: deal.public_name,
                }}
                width={80}
                height={53}
              />
            </div>
            <div>
              <h2 className="mb-1 text-lg font-semibold">{deal.public_name}</h2>
              <TacoRating
                averageRating={deal.deal_review?.average_rating || 5}
                reviewCount={String(deal.deal_review?.review_count || 5)}
                size="sm"
                className="items-center"
              />
            </div>
          </div>
          <Button
            href={`/products/${deal.slug}`}
            className="w-full shrink-0 text-center sm:w-auto"
            aria-label={`Try ${deal.public_name} now`}
          >
            Try now
          </Button>
        </div>
      </header>

      {deal?.snippet_seo && (
        <p className="mb-6 font-bold">{deal?.snippet_seo}</p>
      )}

      <Accordion items={accordionItems} />

      <footer
        className={`mt-4 flex items-center ${
          initialLikes ? 'justify-between' : 'justify-end'
        }`}
      >
        {initialLikes && <LikeButton initialLikes={initialLikes} />}
        <ShareModal
          deal={deal as any}
          buttonText="Share"
          showButtonText
          className="text-blue-500 hover:text-blue-700"
          showColorfulButtons
          title={`Share ${deal.public_name}`}
          shareType={ShareType.ALTERNATIVE_TO}
        />
      </footer>
    </article>
  );
};

export default ProductSummaryCard;

import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

export interface AccordionItem {
  title: string;
  content: React.ReactNode;
}

interface AccordionProps {
  items: AccordionItem[];
  initialIndex?: number;
  className?: string;
}

const Accordion: React.FC<AccordionProps> = ({
  items,
  initialIndex = null,
  className = '',
}) => {
  const [openIndex, setOpenIndex] = useState<number | null>(initialIndex);

  const toggleAccordion = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div
      className={`mx-auto mt-4 overflow-hidden rounded-lg border shadow-sm ${className}`}
    >
      {items.map((item, index) => (
        <div key={index} className="border-b border-gray-200 last:border-b-0">
          <button
            className="flex w-full cursor-pointer items-center justify-between p-4 text-left transition-colors duration-200 hover:bg-blue-100"
            onClick={() => toggleAccordion(index)}
            aria-expanded={openIndex === index ? 'true' : 'false'}
          >
            <h5 className="font-medium">{item.title}</h5>
            <FontAwesomeIcon
              icon={openIndex === index ? faChevronUp : faChevronDown}
              className="text-gray-500"
            />
          </button>
          {openIndex === index && (
            <div className="p-4 text-grace">{item.content}</div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Accordion;

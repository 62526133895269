import { memo } from 'react';

import ProductSummaryCard from '../ui/ProductSummaryCard';
import { DealSkuCardType } from './types';

function AlternativeToDeal({ deal }: { deal: DealSkuCardType }) {
  return (
    <div className="mb-8 flex flex-col gap-6 lg:max-w-screen-2xl lg:flex-row">
      <div className="grow space-y-4">
        <ProductSummaryCard deal={deal} />
      </div>
      <div className="hidden w-full max-w-sm shrink-0 md:block"></div>
    </div>
  );
}

export default memo(AlternativeToDeal);

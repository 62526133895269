import { faHeart as farHeart } from '@fortawesome/free-regular-svg-icons';
import { faHeart as fasHeart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

interface LikeButtonProps {
  initialLikes: number;
}

const LikeButton: React.FC<LikeButtonProps> = ({ initialLikes }) => {
  const [likes, setLikes] = useState(initialLikes);
  const [isLiked, setIsLiked] = useState(false);

  const handleLike = () => {
    if (isLiked) {
      setLikes(likes - 1);
    } else {
      setLikes(likes + 1);
    }
    setIsLiked(!isLiked);
  };

  return (
    <button
      onClick={handleLike}
      className={`flex items-center space-x-2 text-black transition-colors duration-200 hover:text-green-500 ${
        isLiked ? 'text-green-500' : ''
      }`}
    >
      <FontAwesomeIcon
        icon={isLiked ? fasHeart : farHeart}
        className={`h-5 w-5 ${isLiked ? 'animate-pulse' : ''}`}
      />
      <span>{likes}</span>
    </button>
  );
};

export default LikeButton;
